import React, { memo } from 'react'

import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'
import { PageHeader } from '../../components/app/PageHeader'

export const EConsultationsPage = memo(function EConsultationsPage() {
  return (
    <div className="page e-consultations-page pattern-bg">
      <PageHeader
        title="eConsultations"
        icon={
          <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(1.000000, 1.000000)">
                <g
                  id="econsult_icon"
                  transform="translate(17.538462, 11.564102)"
                >
                  <ellipse
                    id="Oval"
                    fill="#E6E6EA"
                    cx="17.3846154"
                    cy="43.1978951"
                    rx="16.5"
                    ry="4.01922989"
                  ></ellipse>
                  <rect
                    id="Rectangle"
                    fill="#E5D7E4"
                    x="7"
                    y="10"
                    width="20"
                    height="4"
                  ></rect>
                  <path
                    d="M10.3311301,38.4358978 C12.8562168,38.4358978 22.2075208,38.4358978 23.9438927,38.4358978 C25.1014739,38.4358978 26.1201763,39.7692312 27,42.4358978 L7,42.4358978 C7.53736218,39.7692312 8.64773887,38.4358978 10.3311301,38.4358978 Z"
                    id="Rectangle-Copy"
                    fill="#E5D7E4"
                    transform="translate(17.000000, 40.435898) rotate(-180.000000) translate(-17.000000, -40.435898) "
                  ></path>
                  <path
                    d="M10.1674291,42.5868778 C8.4209717,42.5868778 6.99995279,41.1658585 6.99995279,39.4194021 L6.99995279,12.4665659 C6.99995279,10.7198722 8.4209717,9.29908943 10.1674291,9.29908943 L21.7210371,9.29908943 C21.9817655,9.29908943 22.1933721,9.51045921 22.1933721,9.77142413 C22.1933721,10.0321529 21.9817655,10.2437588 21.7210371,10.2437588 L10.1674291,10.2437588 C8.94195675,10.2437588 7.94462215,11.2408574 7.94462215,12.4665659 L7.94462215,39.4194021 C7.94462215,40.6451099 8.94195675,41.6422089 10.1674291,41.6422089 L24.1338928,41.6422089 C25.3596022,41.6422089 26.356937,40.6451099 26.356937,39.4194021 L26.356937,16.8758105 C26.356937,16.6148454 26.5685424,16.4034755 26.8292707,16.4034755 C27.0902362,16.4034755 27.3016057,16.6148454 27.3016057,16.8758105 L27.3016057,39.4194021 C27.3016057,41.1658585 25.8805869,42.5868778 24.1338928,42.5868778 L10.1674291,42.5868778 Z"
                    id="Fill-1"
                    fill="#54585A"
                  ></path>
                  <path
                    d="M23.7927979,14.466808 L7.67437587,14.466808 C7.4136471,14.466808 7.20204115,14.255202 7.20204115,13.9944733 C7.20204115,13.7337445 7.4136471,13.5221386 7.67437587,13.5221386 L23.7927979,13.5221386 C24.0535262,13.5221386 24.2651324,13.7337445 24.2651324,13.9944733 C24.2651324,14.255202 24.0535262,14.466808 23.7927979,14.466808"
                    id="Fill-4"
                    fill="#54585A"
                  ></path>
                  <path
                    d="M26.6268052,38.5697151 L7.67437583,38.5697151 C7.41364708,38.5697151 7.20204115,38.3581092 7.20204115,38.0973805 C7.20204115,37.8366517 7.41364708,37.6250458 7.67437583,37.6250458 L26.6268052,37.6250458 C26.8875337,37.6250458 27.0991397,37.8366517 27.0991397,38.0973805 C27.0991397,38.3581092 26.8875337,38.5697151 26.6268052,38.5697151"
                    id="Fill-6"
                    fill="#54585A"
                  ></path>
                  <path
                    d="M19.3136952,40.2178322 L14.9875811,40.2178322 C14.7266162,40.2178322 14.5152464,40.0062262 14.5152464,39.7454975 C14.5152464,39.4847688 14.7266162,39.2731628 14.9875811,39.2731628 L19.3136952,39.2731628 C19.574424,39.2731628 19.7860298,39.4847688 19.7860298,39.7454975 C19.7860298,40.0062262 19.574424,40.2178322 19.3136952,40.2178322"
                    id="Fill-8"
                    fill="#54585A"
                  ></path>
                  <path
                    d="M29.6393331,16.0404146 C29.6447646,16.0404146 29.6504327,16.0404146 29.6558647,16.0406512 C29.7531658,16.0441931 29.8507027,16.0477359 29.9487127,16.0477359 C34.0473968,16.0477359 37.3818438,12.7130529 37.3818438,8.61436874 C37.3818438,4.51544868 34.0473968,1.18076585 29.9487127,1.18076585 C25.8497915,1.18076585 22.5151083,4.51544868 22.5151083,8.61436874 C22.5151083,10.0254686 22.9135227,11.3994905 23.6678413,12.587648 C23.6794134,12.6060691 23.6898048,12.6249625 23.6987792,12.6448006 C23.7275916,12.708094 23.79301,12.8268856 23.9406146,13.0328237 C24.0442921,13.1773577 24.1361611,13.3185863 24.2138602,13.4520205 C24.691863,14.2705768 24.7695619,14.996791 24.4448318,15.6096458 C24.3576861,15.7744901 24.245979,15.9197329 24.1177401,16.0477359 L29.5573828,16.0477359 C29.584306,16.0430128 29.6117015,16.0404146 29.6393331,16.0404146"
                    id="Path"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M12.2641941,31.7125746 L22.0372711,31.7125746 L22.0372711,29.1931414 C22.0372711,28.0380469 21.1254292,27.0985732 20.0041064,27.0985732 L18.6232362,27.0985732 C18.4168258,27.0985732 18.234032,26.964666 18.1726285,26.7674663 C18.1107531,26.5702665 18.1842008,26.356063 18.3537691,26.2382155 C18.9172642,25.8473585 19.2535668,25.19719 19.2535668,24.4990791 L19.2535668,22.574079 C19.2535668,21.4189846 18.3412525,20.4795109 17.2201656,20.4795109 L17.0810632,20.4795109 C15.9599769,20.4795109 15.0478987,21.4189846 15.0478987,22.574079 L15.0478987,24.4990791 C15.0478987,25.19719 15.3842008,25.8473585 15.9476962,26.2382155 C16.1172645,26.3558267 16.1909487,26.5702665 16.1293089,26.7674663 C16.067433,26.9644304 15.8848757,27.0985732 15.6784655,27.0985732 L14.2971226,27.0985732 C13.1760363,27.0985732 12.2641941,28.0380469 12.2641941,29.1931414 L12.2641941,31.7125746 Z M22.5096057,32.6572437 L11.7918595,32.6572437 C11.5311307,32.6572437 11.3195248,32.4456376 11.3195248,32.184909 L11.3195248,29.1931415 C11.3195248,27.5172977 12.6552872,26.1539034 14.2971224,26.1539034 L14.5833575,26.1539034 C14.2742143,25.6681074 14.1032292,25.0954018 14.1032292,24.4990789 L14.1032292,22.5740791 C14.1032292,20.8979995 15.4389915,19.5348415 17.081063,19.5348415 L17.220166,19.5348415 C18.8622376,19.5348415 20.198236,20.8979995 20.198236,22.5740791 L20.198236,24.4990789 C20.198236,25.0954018 20.0272504,25.6681074 19.7181076,26.1539034 L20.0041066,26.1539034 C21.6461775,26.1539034 22.9819403,27.5172977 22.9819403,29.1931415 L22.9819403,32.184909 C22.9819403,32.4456376 22.7703345,32.6572437 22.5096057,32.6572437 L22.5096057,32.6572437 Z"
                    id="Fill-10"
                    fill="#54585A"
                  ></path>
                  <path
                    d="M29.6393331,16.0404146 C29.6447646,16.0404146 29.6504327,16.0404146 29.6558647,16.0406512 C29.7531658,16.0441931 29.8507027,16.0477359 29.9487127,16.0477359 C34.0473968,16.0477359 37.3818438,12.7130529 37.3818438,8.61436874 C37.3818438,4.51544868 34.0473968,1.18076585 29.9487127,1.18076585 C25.8497915,1.18076585 22.5151083,4.51544868 22.5151083,8.61436874 C22.5151083,10.0254686 22.9135227,11.3994905 23.6678413,12.587648 C23.6794134,12.6060691 23.6898048,12.6249625 23.6987792,12.6448006 C23.7275916,12.708094 23.79301,12.8268856 23.9406146,13.0328237 C24.0442921,13.1773577 24.1361611,13.3185863 24.2138602,13.4520205 C24.691863,14.2705768 24.7695619,14.996791 24.4448318,15.6096458 C24.3576861,15.7744901 24.245979,15.9197329 24.1177401,16.0477359 L29.5573828,16.0477359 C29.584306,16.0430128 29.6117015,16.0404146 29.6393331,16.0404146 M29.9487109,16.992406 C29.8714849,16.992406 29.7947306,16.9905164 29.7184481,16.9881553 C29.6964847,16.9909888 29.6816061,16.9905164 29.6516126,16.992406 L29.6492511,16.992406 L21.6292446,16.992406 C21.3746562,16.992406 21.1658843,16.7907186 21.1571461,16.536367 C21.1486441,16.2817786 21.3430098,16.0661577 21.5971258,16.0489172 C22.013725,16.0191603 23.2795819,15.793384 23.6106885,15.166596 C23.7712824,14.8619401 23.6999598,14.4451052 23.3976655,13.9276628 C23.334845,13.8194978 23.2590354,13.7035394 23.1725981,13.5830937 C23.0273552,13.3802268 22.9229693,13.2113665 22.8542445,13.0684858 C22.0141974,11.7343763 21.5704389,10.1948012 21.5704389,8.61436909 C21.5704389,3.99446375 25.3288059,0.236096486 29.9487109,0.236096486 C34.5681446,0.236096486 38.3265114,3.99446375 38.3265114,8.61436909 C38.3265114,13.2340392 34.5681446,16.992406 29.9487109,16.992406"
                    id="Fill-12"
                    fill="#54585A"
                  ></path>
                  <path
                    d="M12.2641941,31.7125746 L22.0372711,31.7125746 L22.0372711,29.1931414 C22.0372711,28.0380469 21.1254292,27.0985732 20.0041064,27.0985732 L18.6232362,27.0985732 C18.4168258,27.0985732 18.234032,26.964666 18.1726285,26.7674663 C18.1107531,26.5702665 18.1842008,26.356063 18.3537691,26.2382155 C18.9172642,25.8473585 19.2535668,25.19719 19.2535668,24.4990791 L19.2535668,22.574079 C19.2535668,21.4189846 18.3412525,20.4795109 17.2201656,20.4795109 L17.0810632,20.4795109 C15.9599769,20.4795109 15.0478987,21.4189846 15.0478987,22.574079 L15.0478987,24.4990791 C15.0478987,25.19719 15.3842008,25.8473585 15.9476962,26.2382155 C16.1172645,26.3558267 16.1909487,26.5702665 16.1293089,26.7674663 C16.067433,26.9644304 15.8848757,27.0985732 15.6784655,27.0985732 L14.2971226,27.0985732 C13.1760363,27.0985732 12.2641941,28.0380469 12.2641941,29.1931414 L12.2641941,31.7125746 Z"
                    id="Path"
                    fill="#FFFFFF"
                  ></path>
                  <path
                    d="M28.6259154,13.4905634 C28.3651865,13.4905634 28.1535807,13.2789573 28.1535807,13.0182287 L28.1535807,10.6565554 L25.7919071,10.6565554 C25.5311784,10.6565554 25.3195724,10.4449494 25.3195724,10.1842208 L25.3195724,7.58637984 C25.3195724,7.32565096 25.5311784,7.11404518 25.7919071,7.11404518 L28.1535807,7.11404518 L28.1535807,4.75237162 C28.1535807,4.49164287 28.3651865,4.28003693 28.6259154,4.28003693 L31.2237563,4.28003693 C31.4844849,4.28003693 31.6960909,4.49164287 31.6960909,4.75237162 L31.6960909,7.11404518 L34.0577643,7.11404518 C34.3184929,7.11404518 34.5300989,7.32565096 34.5300989,7.58637984 L34.5300989,10.1842208 C34.5300989,10.4449494 34.3184929,10.6565554 34.0577643,10.6565554 L31.6960909,10.6565554 L31.6960909,13.0182287 C31.6960909,13.2789573 31.4844849,13.4905634 31.2237563,13.4905634 L28.6259154,13.4905634 Z"
                    id="Path"
                    fill="#E5D7E4"
                  ></path>
                </g>
                <path
                  d="M29.8026558,43.2766768 L39.5757328,43.2766768 L39.5757328,40.7572436 C39.5757328,39.602149 38.6638909,38.6626754 37.5425681,38.6626754 L36.1616979,38.6626754 C35.9552875,38.6626754 35.7724937,38.5287682 35.7110901,38.3315684 C35.6492148,38.1343687 35.7226625,37.9201652 35.8922308,37.8023176 C36.4557259,37.4114607 36.7920285,36.7612921 36.7920285,36.0631813 L36.7920285,34.1381812 C36.7920285,32.9830868 35.8797142,32.0436131 34.7586273,32.0436131 L34.6195248,32.0436131 C33.4984385,32.0436131 32.5863604,32.9830868 32.5863604,34.1381812 L32.5863604,36.0631813 C32.5863604,36.7612921 32.9226625,37.4114607 33.4861578,37.8023176 C33.6557261,37.9199289 33.7294104,38.1343687 33.6677706,38.3315684 C33.6058947,38.5285325 33.4233373,38.6626754 33.2169272,38.6626754 L31.8355843,38.6626754 C30.714498,38.6626754 29.8026558,39.602149 29.8026558,40.7572436 L29.8026558,43.2766768 Z"
                  id="Path"
                  fill="#E5D7E4"
                ></path>
              </g>
            </g>
          </svg>
        }
      />
      <div className="section-header">
        Book a video consultation with an experienced doctor
      </div>

      <div className="content table-container">
        <table className="appointment-table">
          <tr>
            <td className="text-left">Monday - Sunday</td>
            <td className="text-right">08:00-22:00</td>
          </tr>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <div style={{ background: 'white' }} className="section content">
        <div className="button-tile button-tile-primary">
          <a href="tel:03453192881">
            <div className="button-container">
              <PhoneConsultationIcon className="button-icon" />
              <button className="btn mt-1 ">
                Call to book an eConsultation
              </button>
            </div>
          </a>
        </div>

        <div className="section-header">After you’ve booked an appointment</div>
        <div className="content e-consultation-content">
          <div className="paragraph e-consultation-content">
            Once you’ve booked an appointment an email containing a link to join
            the eConsultation will be sent to you.
          </div>
        </div>
      </div>
      <div className="section content">
        <div className="content e-consultation-content">
          <div className="paragraph terms-and-conditions">
            By using this service you are agreeing to our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              terms and conditions
            </a>
            .{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Click here for our privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})
