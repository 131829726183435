import React, { memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, NestDataObject, FieldError } from 'react-hook-form'

import { PageHeader } from '../../components/app/PageHeader'
import { TextInput } from '../../components/form/TextInput'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { requestAppointment } from '../../api'
import { isValidEmailStrict } from '../../util/validation/email'
import { isValidPhoneNumberStrict } from '../../util/validation/phone'
import { loadReCaptcha } from '../../util/recaptcha'
import config from '../../config'

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number',
  email: 'Enter valid email address',
  postcode: 'Enter valid postcode',
  required: 'Required',
}

function getError<T>(errors: NestDataObject<T>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const RequestAppointmentPage = memo(function RequestAppointmentPage() {
  const freeTextDescriptionLabel = `
    Please write a short description about the reason for your appointment.
    Please include any relevant medication you might be taking.
    `

  const { register, handleSubmit, errors } = useForm()

  const [submitErrorMesasge, setSubmitErrorMessage] = useState('')

  const [img, setImg] = useState<any>(null)

  const history = useHistory()

  useEffect(() => loadReCaptcha(config.recaptcha.siteKey), [])

  const onSubmit = async (message: any) => {
    setSubmitErrorMessage('')
    //@ts-ignore
    const recaptchaToken = await grecaptcha.execute(config.recaptcha.siteKey, {
      action: 'request_appointment',
    })

    const response = await requestAppointment({
      ...message,
      recaptchaToken,
      appId: config.appId,
    })

    if (response.success) {
      history.push(ScreenRoutes.REQUEST_CONFIRMED)
      return
    }
    setSubmitErrorMessage(response.message)
  }

  const checkKeyDown = (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLFormElement
    >,
  ) => {
    const keyCode = e.keyCode ? e.keyCode : e.which

    //@ts-ignore
    if (keyCode === 13 && e.target.tagName?.toLowerCase() !== 'textarea') {
      e.preventDefault()
    }
  }

  return (
    <div className="page request-appointment-page">
      <PageHeader
        title="Request an Appointment"
        icon={
          <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1">
            <g stroke="none">
              <g transform="translate(1.000000, 1.000000)">
                <g transform="translate(19.000000, 19.000000)">
                  <g fill="#E5D7E4" stroke="#54585A">
                    <path d="M15.947375,32.0000008 C24.7543455,32.0000008 31.8941467,24.8605149 31.8941467,16.0535443 C31.8941467,7.24625768 24.7543455,0.106772274 15.947375,0.106772274 C7.13977044,0.106772274 -3.16831683e-05,7.24625768 -3.16831683e-05,16.0535443 C-3.16831683e-05,24.8605149 7.13977044,32.0000008 15.947375,32.0000008"></path>
                  </g>
                  <path
                    d="M15.9998439,29.176471 C23.2773474,29.176471 29.176471,23.2768951 29.176471,16.0001557 C29.176471,8.72279361 23.2773474,2.82352948 15.9998439,2.82352948 C8.722966,2.82352948 2.82415446,8.72279361 2.82352948,16.0001557 C2.82352948,23.2772076 8.722966,29.176471 15.9998439,29.176471"
                    fill="#FFFFFF"
                  ></path>
                  <polygon
                    fill="#54585A"
                    points="15.0588236 7.52941227 15.9999992 7.52941227 15.9999992 4.70588255 15.0588236 4.70588255"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="20.6495724 8.47058868 19.7647057 7.8263884 20.7621918 5.64705896 21.6470585 6.2912592"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="24.1736117 11.2941179 23.5294113 10.4090111 25.7087411 9.4117651 26.3529415 10.2966166"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="25.4117641 16.9411765 28.2352943 16.9411765 28.2352943 16 25.4117641 16"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="25.708888 22.5882359 23.5294113 21.590239 24.1734647 20.705883 26.3529415 21.7033692"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="20.7621918 27.2941189 19.7647057 25.114789 20.6495724 24.4705887 21.6470585 26.6499185"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="15.9995288 28.2352943 15.0588236 28.2352943 15.0592939 25.4117641 15.9999992 25.4124868"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="10.2959857 27.2941189 9.4117651 26.6499185 10.4098974 24.4705887 11.2941179 25.1151112"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="6.29093713 22.5882359 5.64705896 21.703129 7.82735475 20.705883 8.47058868 21.5909899"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="3.7647059 16.9411765 6.58823538 16.9411765 6.58823538 16 3.7647059 16"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="7.8263884 11.2941179 5.64705896 10.2963765 6.2912592 9.4117651 8.47058868 10.4092513"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="10.4090111 8.47058868 9.4117651 6.29086367 10.296872 5.64705896 11.2941179 7.8267839"
                  ></polygon>
                  <path
                    d="M15.529929,16.3564014 C15.5072163,16.3564014 15.4731471,16.3632137 15.4449184,16.3943556 C15.418961,16.4235511 15.4147429,16.4553418 15.4160408,16.4767518 C15.4173386,16.4981618 15.4248014,16.5296281 15.454328,16.5555797 C15.4932642,16.5906142 15.5526418,16.6172146 15.6155886,16.5461722 C15.657445,16.5001082 15.6529024,16.4274438 15.6058545,16.3852726 C15.5844397,16.3661333 15.5591312,16.3564014 15.529929,16.3564014 M15.5299291,17.8823531 L15.5299291,17.8823531 C15.1820995,17.8823531 14.8482219,17.754866 14.5896209,17.5232484 C14.3076582,17.2715183 14.1415306,16.9257138 14.1197912,16.5497405 C14.0993497,16.1731186 14.2255678,15.8104456 14.4773549,15.5291956 C14.7453655,15.2301039 15.1288867,15.0588236 15.5299291,15.0588236 C15.8777588,15.0588236 16.2126097,15.186635 16.4712107,15.4182526 C17.0510349,15.9369594 17.1013276,16.8309906 16.5831523,17.4116567 C16.3144928,17.7110727 15.9309715,17.8823531 15.5299291,17.8823531"
                    fill="#54585A"
                  ></path>
                  <polygon
                    fill="#54585A"
                    points="17.0156963 16 16 15.1623315 21.5728799 9.4117651 22.5882359 10.2494336"
                  ></polygon>
                  <polygon
                    fill="#54585A"
                    points="15.1984744 16.9411774 10.3529415 12.2621296 11.1541666 11.2941179 16 15.9731656"
                  ></polygon>
                </g>
              </g>
            </g>
          </svg>
        }
      />
      <div className="content paragraph mb-1">
        We will send you an email with the next available appointment, within 4
        working hours of your request.
      </div>
      <div className="content table-container">
        <table className="appointment-table">
          <tbody>
            <tr>
              <td className="text-left">Monday - Sunday</td>
              <td className="text-right">08:00-22:00</td>
            </tr>
          </tbody>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <form
        onKeyDown={checkKeyDown}
        onSubmit={handleSubmit(onSubmit)}
        className="form"
      >
        <TextInput
          name="policyNumber"
          label="Member number"
          inputRef={register({ required: true })}
          error={getError(errors, 'policyNumber')}
        />
        <TextInput
          error={getError(errors, 'fullName')}
          inputRef={register({ required: true })}
          name="fullName"
          label="Full Name"
        />
        <TextInput
          error={getError(errors, 'dob')}
          inputRef={register({ required: true })}
          name="dob"
          type="date"
          label="Date of birth"
        />

        <div className="form-control">
          <label>Gender</label>
          <div className="radio-group">
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                name="gender"
                value="Male"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Male</span>
            </label>
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                className="radio-button-input"
                name="gender"
                value="Female"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Female</span>
            </label>
          </div>
          {errors.gender ? (
            <div className="error-message">{getError(errors, 'gender')}</div>
          ) : null}
        </div>
        <TextInput
          error={getError(errors, 'email')}
          inputRef={register({
            validate: {
              email: isValidEmailStrict,
            },
          })}
          name="email"
          label="Email"
        />
        <TextInput
          error={getError(errors, 'phone')}
          inputRef={register({
            validate: {
              phone: isValidPhoneNumberStrict,
            },
          })}
          name="phone"
          label="Phone Number"
        />
        <TextInput
          error={getError(errors, 'addressLine1')}
          inputRef={register({ required: true })}
          name="addressLine1"
          label="First Line of Address"
        />
        <TextInput
          inputRef={register}
          name="addressLine2"
          label="Second Line of Address"
        />
        <TextInput
          error={getError(errors, 'city')}
          inputRef={register({ required: true })}
          name="city"
          label="Town or City"
        />
        <TextInput inputRef={register} name="postcode" label="Postcode" />
        <div className="form-control">
          <label>Consultation Type</label>
          <div className="radio-group">
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                name="consultationType"
                value="Telephone"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Telephone</span>
            </label>
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                className="radio-button-input"
                name="consultationType"
                value="Video"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Video</span>
            </label>
          </div>
          {errors.consultationType ? (
            <div className="error-message">
              {getError(errors, 'consultationType')}
            </div>
          ) : null}
        </div>

        <TextInput
          error={getError(errors, 'description')}
          inputRef={register({ required: true })}
          name="description"
          label={freeTextDescriptionLabel}
        />

        <div className="form-control">
          <label className="btn input-file-label">
            <span>Attach an image</span>
            <input
              name="image"
              ref={register}
              type="file"
              accept="image/*"
              onChange={e =>
                setImg(
                  e.target.files && e.target.files[0]
                    ? URL.createObjectURL(e.target.files[0])
                    : null,
                )
              }
              className="input-file"
            />
          </label>
          <img alt="" style={{ height: '60px', marginTop: '10px' }} src={img} />
        </div>
        <div className="form-control">
          <div className="recaptcha-policy content paragraph">
            In addition to HealthHero{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              Terms and Conditions
            </a>{' '}
            , this site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply.
          </div>
          <button type="submit" className="btn btn-request-consultation">
            Request next consultation
          </button>
        </div>
      </form>
      {!!submitErrorMesasge ? (
        <div className="error-message">{submitErrorMesasge}</div>
      ) : null}
    </div>
  )
})
