import React, { memo, useRef, useState } from 'react'
import { useHistory } from 'react-router'

import { PageHeader } from '../../components/app/PageHeader'

import weellbeingImg from '../../assets/images/wellbeing.png'
import travelImg from '../../assets/images/travel.png'
import servicesNearImg from '../../assets/images/services-near-you.png'

import medicinesImg from '../../assets/images/medicines.png'
import conditionsImg from '../../assets/images/conditions.png'
import mentalWellbeingImg from '../../assets/images/mental-wellbeing.png'
import nextImg from '../../assets/images/next.svg'
import { MedicalHistoryIcon } from '../../components/icons/MedicalHistoryIcon'

export const HealthInformationPage = memo(function HealthInformationPage() {
  return (
    <div className="page health-information-page">
      <PageHeader
        title="Health Information"
        icon={
          <MedicalHistoryIcon
            color="#848895"
            className="health-information-icon"
          />
        }
      />
      <div style={{ fontWeight: 600 }} className="section-header">
        Access health information you can trust
      </div>
      <div style={{ padding: '10px 22px' }} className=" content paragraph">
        We have compiled the most up-to-date, medically validated health
        information to provide guidance, tips and tools for living better.
      </div>
      <div className="page-main-container flex-center">
        <div className="page-full-card-container">
          <div className="page-left-card-container">
            <div className="page-double-card-container">
              <div className="page-card-container">
                <a
                  href="https://www.nhs.uk/conditions/"
                  className="page-card-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-icon-container">
                    <div className="card-icon">
                      <img
                        src={conditionsImg}
                        alt="Conditions"
                        style={{ width: 'auto' }}
                      />
                      <div className="card-title">Conditions</div>
                    </div>
                  </div>
                  <div className="card-link-container">
                    <div className="card-link">
                      Read more{' '}
                      <span>
                        {' '}
                        <img src={nextImg} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="page-card-container">
                <a
                  href="https://travelhealthpro.org.uk/countries"
                  className="page-card-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-icon-container">
                    <div className="card-icon">
                      <img
                        src={travelImg}
                        alt="Travel"
                        style={{ width: 'auto' }}
                      />
                      <div className="card-title">Travel</div>
                    </div>
                  </div>
                  <div className="card-link-container">
                    <div className="card-link">
                      Read more{' '}
                      <span>
                        {' '}
                        <img src={nextImg} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="page-single-card-container">
              <div className="page-card-container">
                <a
                  href="https://www.nhs.uk/live-well/"
                  className="page-card-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-icon-container">
                    <div className="card-icon">
                      <img
                        src={weellbeingImg}
                        alt="Wellness"
                        style={{ width: 'auto' }}
                      />
                      <div className="card-title">Wellness</div>
                    </div>
                  </div>
                  <div className="card-link-container">
                    <div className="card-link">
                      Read more{' '}
                      <span>
                        <img src={nextImg} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="page-right-card-container">
            <div className="page-single-card-container">
              <div className="page-card-container">
                <a
                  href="https://www.nhs.uk/conditions/stress-anxiety-depression/"
                  className="page-card-link"
                  rel="noopener noreferrer"
                >
                  <div className="card-icon-container">
                    <div className="card-icon">
                      <img
                        src={mentalWellbeingImg}
                        alt="Mental Health & Wellbeing"
                        style={{ width: 'auto' }}
                      />
                      <div className="card-title">
                        Mental Health & Wellbeing
                      </div>
                    </div>
                  </div>
                  <div className="card-link-container">
                    <div className="card-link">
                      Read more{' '}
                      <span>
                        <img src={nextImg} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="page-double-card-container">
              <div className="page-card-container">
                <a
                  href="https://www.nhs.uk/service-search"
                  className="page-card-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-icon-container">
                    <div className="card-icon">
                      <img
                        src={servicesNearImg}
                        alt="Find services near you"
                        style={{ width: 'auto' }}
                      />
                      <div className="card-title">Find services near you</div>
                    </div>
                  </div>
                  <div className="card-link-container">
                    <div className="card-link">
                      Read more{' '}
                      <span>
                        <img src={nextImg} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </a>
              </div>
              <div className="page-card-container">
                <a
                  href="https://www.nhs.uk/medicines/"
                  className="page-card-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="card-icon-container">
                    <div className="card-icon">
                      <img
                        src={medicinesImg}
                        alt="Medicines"
                        style={{ width: 'auto' }}
                      />
                      <div className="card-title">Medicines</div>
                    </div>
                  </div>
                  <div className="card-link-container">
                    <div className="card-link">
                      Read more{' '}
                      <span>
                        <img src={nextImg} alt="arrow" />
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
