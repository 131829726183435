import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const CheckmarkIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35 35"
    className={cx('checkmark-icon', props.className)}
  >
    <path
      fill="#54585a"
      d="M17.385 34.772C7.8 34.772 0 26.972 0 17.385 0 7.8 7.8 0 17.385 0c9.587 0 17.386 7.8 17.386 17.385h-1.373c0-8.829-7.183-16.012-16.013-16.012-8.83 0-16.012 7.183-16.012 16.012 0 8.83 7.183 16.014 16.012 16.014z"
    />

    <path
      fill="#282d33"
      d="M34.751 18.105l-1.371-.057c.009-.218.013-.437.013-.658h1.373c0 .24-.005.478-.015.715"
    />

    <path
      fill="#54585a"
      d="M19.474 34.653l-.164-1.363c.415-.05.831-.116 1.239-.198l.268 1.346c-.44.088-.893.16-1.343.215m2.664-.534l-.372-1.321c.397-.113.795-.244 1.183-.39l.476 1.288c-.423.157-.855.3-1.287.423m2.553-.957l-.577-1.238c.377-.174.753-.365 1.115-.568l.673 1.19c-.393.22-.802.428-1.21.616m2.37-1.323l-.765-1.14c.346-.232.687-.48 1.013-.738l.852 1.076c-.355.281-.725.55-1.1.802m2.138-1.687l-.937-1.007c.307-.282.606-.581.891-.887l1.013.932c-.308.332-.634.656-.967.962m1.842-1.987l-1.078-.856c.257-.326.506-.67.738-1.018l1.141.767c-.253.38-.523.752-.801 1.107m1.507-2.28l-1.19-.668c.203-.362.394-.736.567-1.111l1.239.573a17.19 17.19 0 0 1-.616 1.205m1.15-2.47l-1.288-.472c.144-.39.276-.789.388-1.185l1.321.37c-.122.431-.265.865-.422 1.288m.74-2.609l-1.346-.268c.082-.41.148-.827.197-1.238l1.363.162c-.053.446-.125.898-.213 1.344"
    />

    <path
      fill="#282d33"
      d="M17.39 34.776v-1.372c.22 0 .44-.005.658-.014l.056 1.372c-.237.01-.475.014-.714.014"
    />

    <path
      fill="#E5D7E4"
      d="M29.28 17.386c0 6.57-5.325 11.895-11.895 11.895-6.57 0-11.895-5.326-11.895-11.895 0-6.57 5.325-11.896 11.895-11.896 6.57 0 11.895 5.326 11.895 11.896"
    />

    <path
      fill="#fff"
      d="M26.24 13.166L22.975 9.9l-8.4 8.4-2.52-2.521-3.265 3.266 5.748 5.823z"
    />

    <path
      fill="#54585a"
      d="M14.532 25.842l-6.712-6.8 4.233-4.233 2.52 2.52 8.4-8.399 4.236 4.237zM9.755 19.05l4.784 4.846 10.729-10.728-2.295-2.296-8.4 8.4-2.52-2.52z"
    />
  </svg>
))
