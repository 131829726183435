import React, { memo } from 'react'
import { Link } from 'react-router-dom'

import { EConsultationIcon } from '../../components/icons/EConsultationIcon'
import { MessageIcon } from '../../components/icons/MessageIcon'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { PageHeader } from '../../components/app/PageHeader'

export const GpConsultationsPage = memo(function GpConsultationsPage() {
  return (
    <div className="page gp-consultations-page pattern-bg">
      <PageHeader
        title="GP Consultations"
        icon={
          <svg width="72px" height="72px" viewBox="0 0 72 72" version="1.1">
            <g
              id="Mobile"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="icon-speak-to-a-gp"
                transform="translate(1.000000, 1.000000)"
              >
                <g id="stethoscope" transform="translate(16.153847, 19.743589)">
                  <g
                    id="Group"
                    transform="translate(0.897436, 5.743590)"
                    fill="#54585A"
                  >
                    <path
                      d="M6.69885582,15.0769238 L0.705273783,9.09667323 C0.0149508281,8.40788578 -0.0473741145,7.75670631 0.0217768931,7.33176642 C0.207861365,6.18872278 1.48730335,5.5197757 1.63272825,5.44722501 C1.92535863,5.30123523 2.28239165,5.4190931 2.42930035,5.71196102 C2.57591248,6.0045328 2.4569014,6.36047546 2.16367735,6.50676139 C1.93663652,6.62195406 1.26738536,7.05755437 1.19318906,7.52335949 C1.18013049,7.60568239 1.14006442,7.85590821 1.54458303,8.2592309 L7.53846169,14.2394815 L6.69885582,15.0769238 Z"
                      id="Shape"
                    ></path>
                    <path
                      d="M14.2400638,7.53846169 L8.25944696,1.54486375 C7.85523114,1.14006734 7.60470636,1.1801315 7.5217903,1.19348618 C7.04206158,1.27153715 6.59934913,1.97874366 6.50695687,2.1639292 C6.36066928,2.4574361 6.00472251,2.57584801 5.71214726,2.42953961 C5.41957201,2.28293444 5.3011205,1.92651182 5.44711198,1.63300481 C5.51966354,1.48788354 6.18861844,0.20761116 7.33167558,0.0218321814 C7.75721267,-0.0476123563 8.40810358,0.0155999797 9.09660304,0.705593816 L15.0769238,6.69889492 L14.2400638,7.53846169 Z"
                      id="Shape"
                    ></path>
                  </g>
                  <ellipse
                    id="Oval"
                    fill="#E6E6EA"
                    cx="20.6410255"
                    cy="26.7435904"
                    rx="14"
                    ry="3.41025639"
                  ></ellipse>
                  <g id="Group" transform="translate(7.358974, 0.000000)">
                    <path
                      d="M15.2097854,25.8461533 C13.5895167,25.8461533 11.9488139,25.2203661 10.6928172,23.9758353 L7.79878262,21.1088934 C7.45168804,20.7653413 7.45168804,20.2082057 7.79878262,19.8643609 C8.14587723,19.5205162 8.70857319,19.5205162 9.05537168,19.8643609 L11.9497021,22.7310086 C13.7109409,24.4754667 16.5063552,24.5455849 18.1808202,22.8870895 C18.9976183,22.0779383 19.4285242,20.9906592 19.3947623,19.8250476 C19.3610015,18.6591419 18.8741205,17.5539677 18.0247455,16.7125435 L11.6573966,10.4053891 C10.4908389,9.2494592 9.86595012,7.71389056 9.89882342,6.08179948 C9.93080822,4.47523224 10.5933086,2.95227956 11.7640126,1.7925354 C14.1762013,-0.597365079 18.1011544,-0.597658506 20.5136397,1.7925354 L26.3037807,7.52847295 C26.6508749,7.87202425 26.6508749,8.42945252 26.3037807,8.77300459 C25.9566866,9.11685013 25.394583,9.11685013 25.0474889,8.77300459 L19.2573467,3.03706743 C17.5375698,1.33397727 14.73949,1.33397727 13.0203056,3.03706743 C12.1756691,3.8737966 11.698266,4.96723641 11.6751658,6.11641874 C11.6520656,7.26560107 12.092153,8.34701226 12.9136893,9.16085751 L19.2804466,15.4683053 C20.4520388,16.6286358 21.1234242,18.1580438 21.1702171,19.7748796 C21.2184899,21.4298548 20.6027825,22.9771578 19.4365202,24.1322073 C18.281217,25.2766964 16.7545336,25.8461533 15.2097854,25.8461533 Z"
                      id="Shape"
                      fill="#54585A"
                    ></path>
                    <path
                      d="M4.71399343,22.9743595 C4.68709284,22.9743595 4.66048496,22.9740668 4.6335847,22.9737735 C2.96634795,22.9535614 1.41197582,22.3050171 0.25701527,21.1479492 C-0.0856717567,20.8049295 -0.0856717567,20.2483651 0.25701527,19.9053454 C0.599409913,19.562033 1.1549605,19.562033 1.49735523,19.9053454 C2.31459931,20.7240814 3.46546652,21.2021406 4.65492967,21.2164941 C5.85521133,21.2308482 6.97508459,20.7894046 7.79086659,19.9724261 C8.60255522,19.1595487 9.03676204,18.0525711 9.01395547,16.8556649 C8.99114826,15.6763337 8.50723492,14.5280532 7.68618937,13.7052166 C7.34350219,13.3621971 7.34350219,12.8056325 7.68618937,12.462613 C8.02858386,12.1193006 8.58413473,12.1193006 8.92652921,12.462613 C10.0823666,13.6202673 10.7361623,15.1683955 10.7680331,16.8219778 C10.8001972,18.5007527 10.1835352,20.0611837 9.03149914,21.2150299 C7.89729876,22.3513004 6.36690272,22.9743595 4.71399343,22.9743595 Z"
                      id="Shape"
                      fill="#E5D7E4"
                    ></path>
                  </g>
                  <ellipse
                    id="Oval"
                    fill="#E5D7E4"
                    cx="33.0256414"
                    cy="8.43589759"
                    rx="3.05128193"
                    ry="3.05128217"
                  ></ellipse>
                  <g id="Group" transform="translate(0.717949, 4.307693)">
                    <path
                      d="M32.1282051,7.89743567 C29.9507401,7.89743567 28.1794872,6.12618279 28.1794872,3.94901768 C28.1794872,1.77155284 29.9507401,0 32.1282051,0 C34.30567,0 36.0769229,1.77155284 36.0769229,3.94901768 C36.0760229,6.12648264 34.3044701,7.89743567 32.1282051,7.89743567 Z M32.1282052,1.34615386 C30.6931974,1.34615386 29.5256411,2.51371017 29.5256411,3.94908125 C29.5256411,5.38372574 30.6931974,6.55128205 32.1282052,6.55128205 C33.5628497,6.55128205 34.730406,5.38372574 34.7307693,3.94871795 C34.7307693,2.51371017 33.5631221,1.34615386 32.1282052,1.34615386 Z"
                      id="Shape"
                      fill="#54585A"
                    ></path>
                    <ellipse
                      id="Oval"
                      fill="#E5D7E4"
                      cx="32.0512838"
                      cy="3.87179482"
                      rx="2.00000095"
                      ry="2"
                    ></ellipse>
                    <ellipse
                      id="Oval"
                      fill="#E5D7E4"
                      transform="translate(2.153846, 7.000000) rotate(-135.000000) translate(-2.153846, -7.000000) "
                      cx="2.15384638"
                      cy="7.00000024"
                      rx="1"
                      ry="1"
                    ></ellipse>
                    <ellipse
                      id="Oval"
                      fill="#E5D7E4"
                      transform="translate(5.384615, 3.769231) rotate(-135.000000) translate(-5.384615, -3.769231) "
                      cx="5.38461518"
                      cy="3.7692306"
                      rx="1"
                      ry="1"
                    ></ellipse>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }
      />

      <div className="content no-bg">
        <div className="section-header">
          Speak to a doctor at a time and place that suits you
        </div>
      </div>

      <div className="speak-to-doctor-section">
        <div className="speak-to-doctor-section-background" />
        <div className="button-tile-container">
          <div className="button-tile-col primary">
            <div className="button-tile button-tile-secondary">
              <Link className="button-tile-link" to={ScreenRoutes.E_CONSULT}>
                <div className="button-container">
                  <div className="icon-container">
                    <EConsultationIcon className="button-icon" />
                  </div>
                  <button className="btn">eConsultations</button>
                </div>
              </Link>
            </div>

            <div className="button-tile button-tile-primary request-appointment-btn">
              <Link
                className="button-tile-link"
                to={ScreenRoutes.REQUEST_APPOINTMENT}
              >
                <div className="button-container">
                  <div className="icon-container">
                    <svg
                      width="42px"
                      height="42px"
                      viewBox="0 0 42 42"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="clock-icon"
                    >
                      <g
                        id="Mobile"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g transform="translate(-255.000000, -255.000000)">
                          <g transform="translate(256.000000, 256.000000)">
                            <g stroke="#FFFFFF" strokeWidth="1.66666667">
                              <circle cx="20" cy="20" r="20"></circle>
                            </g>
                            <polygon
                              fill="#FFFFFF"
                              points="18.7616094 9.20536677 19.9999996 9.20536677 19.9999996 5.49019623 18.7616094 5.49019623"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="26.1178587 10.4437561 24.9535599 9.59612433 26.2660423 6.7285862 27.4303411 7.57621792"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="30.7547513 14.1589266 29.9071198 12.9943124 32.7746574 11.6821467 33.622289 12.8464249"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="32.383901 21.5892673 36.0990702 21.5892673 36.0990702 20.3508771 32.383901 20.3508771"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="32.7748507 29.0196101 29.9071198 27.7064557 30.754558 26.5428289 33.622289 27.8553113"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="26.2660423 35.211558 24.9535599 32.3440203 26.1178587 31.4963888 27.4303411 34.3639264"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="19.9993807 36.4499486 18.7616094 36.4499486 18.7622283 32.7347794 19.9999996 32.7357303"
                            ></polygon>
                            <polygon
                              fill="#FFFFFF"
                              points="12.4947177 35.211558 11.3312696 34.3639264 12.6446015 31.4963888 13.8080495 32.3444442"
                            ></polygon>
                            <polygon
                              id="Fill-17"
                              fill="#FFFFFF"
                              points="7.22491701 29.0196101 6.37770907 27.8549952 9.24651872 26.5428289 10.092879 27.7074437"
                            ></polygon>
                            <polygon
                              id="Fill-18"
                              fill="#FFFFFF"
                              points="3.90092882 21.5892673 7.61609904 21.5892673 7.61609904 20.3508771 3.90092882 20.3508771"
                            ></polygon>
                            <polygon
                              id="Fill-19"
                              fill="#FFFFFF"
                              points="9.2452472 14.1589266 6.37770907 12.846109 7.22534079 11.6821467 10.092879 12.9946285"
                            ></polygon>
                            <polygon
                              id="Fill-20"
                              fill="#FFFFFF"
                              points="12.6434353 10.4437561 11.3312696 7.57569747 12.4958838 6.7285862 13.8080495 9.59664472"
                            ></polygon>
                            <path
                              d="M19.3814857,20.8198258 C19.3516005,20.8198258 19.3067727,20.8287893 19.2696296,20.8697655 C19.2354751,20.9081806 19.229925,20.9500104 19.2316327,20.9781815 C19.2333404,21.0063526 19.2431599,21.0477556 19.2820106,21.0819023 C19.3332424,21.1280005 19.4113709,21.1630009 19.4941956,21.0695241 C19.5492698,21.0089136 19.5432927,20.9133026 19.4813876,20.8578141 C19.4532102,20.8326309 19.4199095,20.8198258 19.3814857,20.8198258 M19.3814857,22.8276567 L19.3814857,22.8276567 C18.9238151,22.8276567 18.4845025,22.6599107 18.1442381,22.3551506 C17.7732345,22.0239268 17.5546456,21.5689209 17.5260412,21.0742193 C17.4991445,20.5786641 17.6652209,20.1014628 17.9965197,19.731397 C18.3491652,19.3378553 18.8537983,19.1124865 19.3814857,19.1124865 C19.8391563,19.1124865 20.2797496,19.2806594 20.6200141,19.5854195 C21.3829406,20.2679283 21.4491153,21.4442851 20.7673056,22.2083195 C20.4138063,22.6022879 19.909173,22.8276567 19.3814857,22.8276567"
                              id="Fill-21"
                              fill="#FFFFFF"
                            ></path>
                            <polygon
                              id="Fill-22"
                              fill="#FFFFFF"
                              points="21.3364425 20.3508771 20 19.2486816 27.332737 11.6821467 28.6687317 12.7843422"
                            ></polygon>
                            <polygon
                              id="Fill-23"
                              fill="#FFFFFF"
                              points="18.9453609 21.5892665 12.5696589 15.4326259 13.6239025 14.1589266 20 20.3155671"
                            ></polygon>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>

                  <button className="btn">Request appointment online</button>
                </div>
              </Link>
            </div>
          </div>
          <div className="button-tile-col secondary">
            <div className="button-tile button-tile-primary">
              <a href="tel:03453192881">
                <div className="button-container">
                  <div className="icon-container">
                    <PhoneConsultationIcon className="button-icon" />
                  </div>
                  <button className="btn">
                    Book a Phone Consultation 24/7
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="content section">
        <div className="section-header mt-1">
          Private Prescriptions & Specialist Referrals
        </div>
        <div className="content">
          <div className="paragraph">
            Private prescription medication and open referral letters are
            subject to a consultation with the GP.
          </div>
          <br />
          <div className="paragraph">
            To find out more tap on the button below.
          </div>
          <div className="flex-center mt-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/private-prescription-service/"
            >
              <button className="btn btn-primary-2 btn-bold">
                Prescriptions & Referrals
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="content section">
        <div className="section-header mt-1">Feedback</div>
        <div className="content">
          <div className="paragraph">
            How did you find the service? Let us know your experience of the GP
            service by completing a 1 minute survey.
          </div>
          <div className="flex-center mt-2 mb-2">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://medicalsolutions.typeform.com/to/uJH6E4wN"
            >
              <button className="btn btn-primary-2 btn-bold">
                Leave Feedback
              </button>
            </a>
          </div>
          <div className="paragraph terms-and-conditions">
            By using this service you are agreeing to our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              terms and conditions
            </a>
            .{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Click here for our privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
})
