import React, { memo, useState } from 'react'
import Modal from 'react-modal'

import iconSrc from '../assets/images/cf-mobile-favicon.svg'
import iosShareSrc from '../assets/images/ios-share-icon.svg'

const needsToSeePrompt = () => {
  // @ts-ignore
  if (window.navigator.standalone) {
    return false
  }

  const isApple = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
  return isApple
}

export const IosPWAPrompt = memo(function IosPWAPrompt() {
  const [isOpen, setIsOpen] = useState(needsToSeePrompt())
  const closeModal = () => setIsOpen(false)
  // const
  return (
    <Modal
      style={{
        content: {
          zIndex: 1000,
          bottom: '10px',
          top: 'auto',
        },
        overlay: { position: 'absolute', zIndex: 99 },
      }}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={closeModal}
    >
      <div className="ios-pwa-prompt">
        <div className="prompt-content">
          <div>
            <div className="prompt-header">Install GP Line</div>
            <div className="prompt-app-icon mt-2">
              <img src={iconSrc} />
            </div>
            <div className="prompt-text">
              Install this application on your home screen for quick and easy
              access
            </div>
          </div>
          <div className="prompt-footer mt-2">
            Just tap <img src={iosShareSrc} /> then
            <div>'Add to Home Screen'</div>
          </div>
          <div>
            <button onClick={closeModal} className="btn w-full mt-2">
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
})
