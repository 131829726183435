import React, { memo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { TextInput } from '../../components/form/TextInput'
import { PageHeader } from '../../components/app/PageHeader'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { useForm, NestDataObject, FieldError } from 'react-hook-form'
import { messageDr } from '../../api'
import { isValidEmailStrict } from '../../util/validation/email'
import config from '../../config'
import { loadReCaptcha } from '../../util/recaptcha'
import { TextArea } from '../../components/form/TextArea'

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number',
  email: 'Enter valid email address',
  postcode: 'Enter valid postcode',
  required: 'Required',
}

function getError<T>(errors: NestDataObject<T>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const MessageDrPage = memo(function MessageDrPage() {
  const { register, handleSubmit, formState, errors } = useForm()

  const [submitErrorMesasge, setSubmitErrorMessage] = useState('')

  const [img, setImg] = useState<any>(null)

  const history = useHistory()

  useEffect(() => loadReCaptcha(config.recaptcha.siteKey), [])

  const onSubmit = async (message: any) => {
    setSubmitErrorMessage('')
    try {
      //@ts-ignore
      const recaptchaToken = await grecaptcha.execute(
        config.recaptcha.siteKey,
        { action: 'message_dr' },
      )

      const response = await messageDr({
        ...message,
        recaptchaToken,
        appId: config.appId,
      })
      if (response.success) {
        history.push(ScreenRoutes.MESSAGE_SENT)
        return
      }
      setSubmitErrorMessage(
        response.message || 'Message submit failed. Please try again later',
      )
    } catch {
      setSubmitErrorMessage('Message submit failed. Please try again later')
    }
  }

  const checkKeyDown = (
    e: React.KeyboardEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLFormElement
    >,
  ) => {
    const keyCode = e.keyCode ? e.keyCode : e.which

    //@ts-ignore
    if (keyCode === 13 && e.target.tagName?.toLowerCase() !== 'textarea') {
      e.preventDefault()
    }
  }

  return (
    <div className="page message-dr-page">
      <PageHeader
        title="Message Dr."
        icon={
          <svg
            width="73px"
            height="72px"
            viewBox="0 0 73 72"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(1.700000, 1.000000)">
                <g transform="translate(15.000000, 17.000000)">
                  <g transform="translate(0.000000, 0.022167)">
                    <ellipse
                      fill="#E6E6EA"
                      cx="18.9188026"
                      cy="32.6503992"
                      rx="18.4722214"
                      ry="4.49964428"
                    ></ellipse>
                    <path
                      d="M20.8559619,29.9435713 C20.7811383,29.9435713 20.7063147,29.9416421 20.6314911,29.9393284 C20.6021799,29.9424145 20.5724819,29.9435713 20.5420119,29.9435713 L20.538156,29.9435713 L5.45931155,29.9435713 C5.08596589,29.9435713 4.76623084,29.6762895 4.69989257,29.3087298 C4.63393998,28.94117 4.84105425,28.5790083 5.19087294,28.4490319 C7.10234078,27.7401387 8.62387876,26.204715 9.32737296,24.2982611 C7.3484097,21.7488659 6.26308462,18.588928 6.26308462,15.3506939 C6.26308462,7.30370722 12.809361,0.757816553 20.8559619,0.757816553 C28.9029488,0.757816553 35.4496112,7.30370722 35.4496112,15.3506939 C35.4496112,23.3972945 28.9029488,29.9435713 20.8559619,29.9435713"
                      stroke="#54585A"
                      fill="#F7AD33"
                    ></path>
                    <path
                      d="M20.5522064,27.6533509 C20.5580339,27.6533509 20.5638615,27.6533509 20.5693254,27.6537153 L20.6851503,27.6569929 C20.7441551,27.6591776 20.8035251,27.6609993 20.8628942,27.6609993 C27.6586968,27.6609993 33.1877062,22.1327175 33.1877062,15.336916 C33.1877062,8.54111407 27.6586968,3.01283264 20.8628942,3.01283264 C14.0674567,3.01283264 8.53881097,8.54111407 8.53881097,15.336916 C8.53881097,18.1935719 9.5389863,20.9784733 11.3546724,23.1780569 C11.5101985,23.3663646 11.5608266,23.6205973 11.4898018,23.8540679 C11.0348786,25.3474114 10.1505284,26.6673797 8.98098606,27.6609993 L20.4465798,27.6609993 C20.4811814,27.6558999 20.5165117,27.6533509 20.5522064,27.6533509"
                      fill="#FFFFFF"
                    ></path>
                  </g>
                  <path
                    d="M22.9481964,22.8014898 L18.8651681,22.8014898 C18.4553803,22.8014898 18.1227994,22.4689084 18.1227994,22.0591211 L18.1227994,18.3472776 L14.4109555,18.3472776 C14.0011679,18.3472776 13.6685867,18.0146962 13.6685867,17.6049089 L13.6685867,13.5218806 C13.6685867,13.1120929 14.0011679,12.7795119 14.4109555,12.7795119 L18.1227994,12.7795119 L18.1227994,9.06766802 C18.1227994,8.65788046 18.4553803,8.32529926 18.8651681,8.32529926 L22.9481964,8.32529926 C23.3579837,8.32529926 23.6905651,8.65788046 23.6905651,9.06766802 L23.6905651,12.7795119 L27.4024086,12.7795119 C27.8121959,12.7795119 28.1447773,13.1120929 28.1447773,13.5218806 L28.1447773,17.6049089 C28.1447773,18.0146962 27.8121959,18.3472776 27.4024086,18.3472776 L23.6905651,18.3472776 L23.6905651,22.0591211 C23.6905651,22.4689084 23.3579837,22.8014898 22.9481964,22.8014898 L22.9481964,22.8014898 Z"
                    stroke="#54585A"
                    fill="#F7AD33"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        }
      />

      <div className="content paragraph message-dr-text mb-1">
        If you have a general healthcare query, you can message a doctor using
        this simple service. The doctor will respond to your email address the
        next working day.
      </div>
      <div className="content table-container">
        <table className="appointment-table">
          <tbody>
            <tr>
              <td className="text-left">Monday - Sunday</td>
              <td className="text-right">08:00-22:00</td>
            </tr>
          </tbody>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <form
        onKeyDown={checkKeyDown}
        onSubmit={handleSubmit(onSubmit)}
        className="form"
      >
        <TextInput
          name="policyNumber"
          label="Employee number"
          inputRef={register({ required: true })}
          error={getError(errors, 'policyNumber')}
        />
        <TextInput
          error={getError(errors, 'fullName')}
          inputRef={register({ required: true })}
          name="fullName"
          label="Full Name"
        />
        <TextInput
          error={getError(errors, 'dob')}
          inputRef={register({ required: true })}
          name="dob"
          type="date"
          label="Date of birth"
        />

        <div className="form-control">
          <label>Gender</label>
          <div className="radio-group">
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                name="gender"
                value="Male"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Male</span>
            </label>
            <label className="radio-button">
              <input
                ref={register({ required: true })}
                type="radio"
                className="radio-button-input"
                name="gender"
                value="Female"
              />
              <span className="radio-button-control"></span>
              <span className="radio-button-label">Female</span>
            </label>
          </div>
          {errors.gender ? (
            <div className="error-message">{getError(errors, 'gender')}</div>
          ) : null}
        </div>
        <TextInput
          error={getError(errors, 'email')}
          inputRef={register({
            validate: {
              email: isValidEmailStrict,
            },
          })}
          name="email"
          label="Email"
        />
        <TextArea
          rows={5}
          inputRef={register}
          name="question"
          label="Type your question below"
        />
        <div className="form-control">
          <label className="btn input-file-label">
            <span>Attach an image</span>
            <input
              name="image"
              ref={register}
              type="file"
              onChange={e =>
                setImg(
                  e.target.files && e.target.files[0]
                    ? URL.createObjectURL(e.target.files[0])
                    : null,
                )
              }
              accept="image/*"
              className="input-file"
            />
          </label>
          <img alt="" style={{ height: '60px', marginTop: '10px' }} src={img} />
        </div>
        <div className="form-control">
          <div className="recaptcha-policy content paragraph">
            In addition to HealthHero{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.healthhero.com/privacy-policy/"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener  noreferrer"
              href="https://www.healthhero.com/terms-and-conditions/"
            >
              Terms and Conditions
            </a>{' '}
            , this site is protected by reCAPTCHA and the Google{' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>{' '}
            apply.
          </div>
          <button
            disabled={formState.isSubmitting}
            type="submit"
            className="btn btn-request-consultation"
          >
            {formState.isSubmitting ? 'Please wait....' : 'Send'}
          </button>
        </div>
        {!!submitErrorMesasge ? (
          <div className="error-message">{submitErrorMesasge}</div>
        ) : null}
      </form>
    </div>
  )
})
