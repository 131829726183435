import React, { memo, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { StethoscopeIcon } from '../../components/icons/StethoscopeIcon'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'

import heroImg from '../../assets/images/home-hero-mobile.png'
import logoImg from '../../assets/images/cf-logo.svg'
import heroImgDesktop from '../../assets/images/home-hero-desktop.jpg'
import medsolLogoImg from '../../assets/images/HealthHeroLogo.svg'
import cfSubLogo from '../../assets/images/cf-mobile-gp24-icon.png'

export const HomePage = memo(function HomePage() {
  useEffect(() => {
    //@ts-ignore
    window.gtag('config', 'UA-78156948-67')
  }, [])
  return (
    <div className="pattern-bg page home-page">
      <div className="header">
        <img
          style={{ width: 'auto', height: '60px' }}
          src={logoImg}
          alt="logo"
        />
      </div>
      <div className="hero-unit unselectable">
        <img
          className="hero-banner-mobile unselectable"
          src={heroImg}
          alt="banner"
        />
        <img
          className="hero-banner-desktop"
          src={heroImgDesktop}
          alt="banner"
        />

        <div className="hero-logo-container">
          <img src={logoImg} className="hero-logo" alt="logo" />

          <br />
          <img src={cfSubLogo} className="hero-sub-logo mt-1" alt="" />
        </div>

        <div className="hero-text hide-mobile">GP Advice Line</div>
        <div className="hero-description hide-mobile">
          With 24/7 access to our private helpline, you can <br /> speak to a GP
          anytime you need, wherever you are
        </div>
      </div>
      <div className="bottom-container">
        <div className="button-tile-container">
          <div className="button-tile button-tile-primary">
            <Link className="button-tile-link" to={ScreenRoutes.GP_CONSULT}>
              <div className="button-container">
                <StethoscopeIcon className="thethoscope-icon button-icon" />

                <button className="btn">Speak to a GP</button>
              </div>
            </Link>
          </div>
          <div className="button-tile button-tile-secondary">
            <a
              className="button-tile-link"
              href="https://medicalsolutions.typeform.com/to/uJH6E4wN"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="button-container">
                <svg
                  width="47px"
                  height="38px"
                  viewBox="0 0 47 38"
                  version="1.1"
                  className="button-icon"
                >
                  <defs>
                    <path
                      d="M0,4 C0,6.209 6.716,7.999 15,7.999 L15,7.999 C23.285,7.999 30,6.209 30,4 L30,4 C30,1.79 23.285,-0.001 15,-0.001 L15,-0.001 C6.716,-0.001 0,1.79 0,4 L0,4 Z"
                      id="path-1"
                    ></path>
                  </defs>
                  <g
                    id="Mobile"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g id="button-feedback">
                      <g transform="translate(5.000000, 30.000000)">
                        <mask id="mask-2" fill="white">
                          <use xlinkHref="#path-1"></use>
                        </mask>
                        <g id="Clip-2"></g>
                        <polygon
                          fill="#CDADCB"
                          mask="url(#mask-2)"
                          points="-5 13 35 13 35 -5 -5 -5"
                        ></polygon>
                      </g>
                      <g>
                        <path
                          d="M45.7156,5.7886 L40.4426,0.5156 C40.2556,0.3276 40.0006,0.2226 39.7356,0.2226 C39.4706,0.2226 39.2156,0.3276 39.0286,0.5156 L35.1186,4.4256 C33.5696,3.2316 31.6946,2.5956 29.6646,2.5956 L9.3796,2.5956 C4.3946,2.5956 0.3406,6.6496 0.3406,11.6346 L0.3406,19.4746 C0.3406,24.4586 4.3956,28.5136 9.3796,28.5136 L22.0206,28.5136 C21.7016,29.4176 21.0956,30.1336 20.8556,30.3926 C20.4436,30.8396 20.3406,31.4906 20.5976,32.0426 C20.8436,32.5736 21.3756,32.9106 21.9576,32.9106 C21.9796,32.9106 22.0016,32.9106 22.0236,32.9096 C22.2806,32.8976 27.9966,32.5966 30.5786,28.4676 C35.1346,28.0076 38.7036,24.1496 38.7036,19.4746 L38.7036,15.2086 C38.7036,14.9246 38.6196,14.6616 38.4826,14.4346 L45.7156,7.2026 C46.1066,6.8116 46.1066,6.1796 45.7156,5.7886"
                          fill="#E5D7E4"
                        ></path>
                        <path
                          d="M22.4377,20.2193 L7.9217,20.2193 C7.5077,20.2193 7.1717,20.5553 7.1717,20.9693 C7.1717,21.3833 7.5077,21.7193 7.9217,21.7193 L22.4377,21.7193 C22.8517,21.7193 23.1877,21.3833 23.1877,20.9693 C23.1877,20.5553 22.8517,20.2193 22.4377,20.2193"
                          fill="#E5D7E4"
                        ></path>
                        <path
                          d="M21.9573,32.9107 C21.3753,32.9107 20.8443,32.5737 20.5973,32.0427 C20.3413,31.4907 20.4433,30.8397 20.8553,30.3927 C21.0963,30.1337 21.7013,29.4177 22.0203,28.5137 L9.3793,28.5137 C4.3953,28.5137 0.3403,24.4587 0.3403,19.4737 L0.3403,11.6347 C0.3403,6.6497 4.3953,2.5957 9.3793,2.5957 L29.6643,2.5957 C31.8233,2.5957 33.8113,3.3067 35.4133,4.6507 C36.0473,5.1827 36.1293,6.1297 35.5963,6.7647 C35.0643,7.3987 34.1183,7.4817 33.4833,6.9477 C32.4293,6.0627 31.1093,5.5957 29.6643,5.5957 L9.3793,5.5957 C6.0493,5.5957 3.3403,8.3047 3.3403,11.6347 L3.3403,19.4737 C3.3403,22.8047 6.0493,25.5137 9.3793,25.5137 L23.7063,25.5137 C24.4823,25.5137 25.1303,26.1047 25.2003,26.8787 C25.2763,27.7227 25.1613,28.5117 24.9453,29.2207 C26.2453,28.7167 27.6173,27.8527 28.3053,26.3797 C28.5513,25.8507 29.0813,25.5137 29.6643,25.5137 C32.9943,25.5137 35.7033,22.8047 35.7033,19.4737 L35.7033,15.2087 C35.7033,14.3807 36.3743,13.7087 37.2033,13.7087 C38.0323,13.7087 38.7033,14.3807 38.7033,15.2087 L38.7033,19.4737 C38.7033,24.1497 35.1353,28.0077 30.5783,28.4677 C27.9973,32.5967 22.2803,32.8977 22.0233,32.9097 C22.0013,32.9107 21.9793,32.9107 21.9573,32.9107"
                          fill="#FEFEFE"
                        ></path>
                        <path
                          d="M26.9294,15.4419 L26.4054,19.8259 L30.7884,19.2999 L43.5944,6.4959 L39.7354,2.6359 L26.9294,15.4419 Z M25.2614,21.9689 C24.9974,21.9689 24.7424,21.8649 24.5544,21.6759 C24.3374,21.4599 24.2324,21.1549 24.2684,20.8499 L24.9844,14.8609 C25.0114,14.6379 25.1124,14.4309 25.2704,14.2729 L39.0284,0.5149 C39.2164,0.3279 39.4704,0.2219 39.7354,0.2219 C40.0004,0.2219 40.2554,0.3279 40.4424,0.5149 L45.7154,5.7889 C46.1064,6.1789 46.1064,6.8119 45.7154,7.2029 L31.9584,20.9599 C31.7994,21.1179 31.5924,21.2179 31.3694,21.2459 L25.3804,21.9629 C25.3404,21.9669 25.3014,21.9689 25.2614,21.9689 L25.2614,21.9689 Z"
                          fill="#FEFEFE"
                        ></path>
                        <path
                          d="M31.259,15.7212 C31.067,15.7212 30.875,15.6482 30.729,15.5012 C30.436,15.2082 30.436,14.7342 30.729,14.4412 L41.842,3.3282 C42.134,3.0352 42.609,3.0352 42.902,3.3282 C43.195,3.6212 43.195,4.0962 42.902,4.3892 L31.789,15.5012 C31.643,15.6482 31.451,15.7212 31.259,15.7212"
                          fill="#FEFEFE"
                        ></path>
                        <path
                          d="M30.363,11.3443 L7.922,11.3443 C7.508,11.3443 7.172,11.0083 7.172,10.5943 C7.172,10.1803 7.508,9.8443 7.922,9.8443 L30.363,9.8443 C30.777,9.8443 31.113,10.1803 31.113,10.5943 C31.113,11.0083 30.777,11.3443 30.363,11.3443"
                          fill="#FEFEFE"
                        ></path>
                        <path
                          d="M25.8821,16.5318 L7.9221,16.5318 C7.5081,16.5318 7.1721,16.1958 7.1721,15.7818 C7.1721,15.3678 7.5081,15.0318 7.9221,15.0318 L25.8821,15.0318 C26.2961,15.0318 26.6321,15.3678 26.6321,15.7818 C26.6321,16.1958 26.2961,16.5318 25.8821,16.5318"
                          fill="#FEFEFE"
                        ></path>
                        <path
                          d="M22.4377,21.7193 L7.9217,21.7193 C7.5077,21.7193 7.1717,21.3833 7.1717,20.9693 C7.1717,20.5553 7.5077,20.2193 7.9217,20.2193 L18.4377,20.2193 C18.8517,20.2193 19.1877,20.5553 19.1877,20.9693 C19.1877,21.3833 18.8517,21.7193 18.4377,21.7193"
                          fill="#FEFEFE"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
                <button className="btn">Feedback</button>
              </div>
            </a>
          </div>
          <div className="button-tile button-tile-secondary-2">
            <Link
              className="button-tile-link"
              to={ScreenRoutes.HEALTH_INFORMATION}
            >
              <div className="button-container">
                <svg
                  width="30px"
                  height="44px"
                  viewBox="0 0 30 44"
                  version="1.1"
                  className="button-icon"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g id="button-medical-info">
                      <ellipse
                        fill="#CDADCB"
                        cx="15"
                        cy="40"
                        rx="15"
                        ry="4"
                      ></ellipse>
                      <polyline
                        fill="#E5D7E4"
                        points="21.4929576 6.99099799 27 6.99099799 27 36.990998 4 36.990998 4 6.99099799 9.50704241 6.99099799"
                      ></polyline>
                      <path
                        d="M27.0259737,37.990998 L3.97402595,37.990998 C3.43603894,37.990998 3,37.5338065 3,36.9697215 L3,7.01227458 C3,6.44818949 3.43603894,5.99099799 3.97402595,5.99099799 L8.02597442,5.99099799 C8.56396148,5.99099799 9,6.44818949 9,7.01227458 C9,7.57635971 8.56396148,8.03355118 8.02597442,8.03355118 L4.9480519,8.03355118 L4.9480519,35.948445 L26.0519474,35.948445 L26.0519474,8.03355118 L21.5064942,8.03355118 C20.9685071,8.03355118 20.5324678,7.57635971 20.5324678,7.01227458 C20.5324678,6.44818949 20.9685071,5.99099799 21.5064942,5.99099799 L27.0259737,5.99099799 C27.5639607,5.99099799 28,6.44818949 28,7.01227458 L28,36.9697215 C28,37.5338065 27.5636359,37.990998 27.0259737,37.990998 Z"
                        fill="#FFFFFF"
                      ></path>
                      <polygon
                        fill="#E5D7E4"
                        points="18.0825658 33.990998 7 33.990998 7 10.990998 25 10.990998 25 27.8325048"
                      ></polygon>
                      <path
                        d="M17.5439997,34.990998 L6.66666667,34.990998 C6.29833333,34.990998 6,34.6803844 6,34.2976653 L6,9.68433134 C6,9.30161133 6.29833333,8.99099799 6.66666667,8.99099799 L24.3333336,8.99099799 C24.7013334,8.99099799 25,9.30161133 25,9.68433134 L25,27.7071849 C25,27.8995843 24.9233328,28.082972 24.7883334,28.2140117 L17.9989995,34.8044906 C17.875333,34.9244375 17.7126669,34.990998 17.5439997,34.990998 Z M7,33.990998 L17.3536935,33.990998 L24,27.8530876 L24,10.990998 L7,10.990998 L7,33.990998 Z"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M17.6956522,34.990998 C17.3113044,34.990998 17,34.6403893 17,34.2083895 L17,26.7736067 C17,26.3416067 17.3113044,25.990998 17.6956522,25.990998 L24.304348,25.990998 C24.6883478,25.990998 25,26.3416067 25,26.7736067 C25,27.2056067 24.6883478,27.5562154 24.304348,27.5562154 L18.3913044,27.5562154 L18.3913044,34.2083895 C18.3913044,34.6403893 18.0796522,34.990998 17.6956522,34.990998 Z"
                        fill="#FFFFFF"
                      ></path>
                      <g
                        transform="translate(7.000000, 0.990998)"
                        fill="#FFFFFF"
                      >
                        <path d="M13.5194956,9.00900201 C12.9536908,9.00900201 12.4951055,8.61400203 12.4951055,8.12664912 L12.4951055,5.77370794 L2.59266614,5.77370794 L2.59266614,8.12664912 C2.59266614,8.61400203 2.13408088,9.00900201 1.56827594,9.00900201 C1.0024711,9.00900201 0.54388573,8.61400203 0.54388573,8.12664912 L0.54388573,4.89135497 C0.54388573,4.40400203 1.0024711,4.00900201 1.56827594,4.00900201 L13.5194956,4.00900201 C14.0853005,4.00900201 14.5438857,4.40400203 14.5438857,4.89135497 L14.5438857,8.12664912 C14.5438857,8.61400203 14.0849592,9.00900201 13.5194956,9.00900201 Z"></path>
                        <path d="M13.8450546,9.00900201 L2.24271652,9.00900201 C1.85696194,9.00900201 1.54388573,8.73330978 1.54388573,8.39361743 L1.54388573,5.62438665 C1.54388573,5.28469432 1.85696194,5.00900201 2.24271652,5.00900201 C2.62882054,5.00900201 2.94154731,5.28469432 2.94154731,5.62438665 L2.94154731,7.77823285 L13.1462243,7.77823285 L13.1462243,5.62438665 C13.1462243,5.28469432 13.4593005,5.00900201 13.8450546,5.00900201 C14.2311591,5.00900201 14.5438857,5.28469432 14.5438857,5.62438665 L14.5438857,8.39361743 C14.5438857,8.73330978 14.2311591,9.00900201 13.8450546,9.00900201 Z"></path>
                        <path d="M10.6308421,5.00900201 C10.1265377,5.00900201 9.71779879,4.54589866 9.71779879,3.97451929 C9.71779879,2.92900197 8.96697261,2.07796759 8.04388573,2.07796759 C7.12079885,2.07796759 6.36997267,2.92900197 6.36997267,3.97451929 C6.36997267,4.54589866 5.96123355,5.00900201 5.4569292,5.00900201 C4.95262485,5.00900201 4.54388573,4.54589866 4.54388573,3.97451929 C4.54388573,1.78796745 6.11401617,0.0090020091 8.04388573,0.0090020091 C9.9737555,0.0090020091 11.5438857,1.78796745 11.5438857,3.97451929 C11.5438857,4.54589866 11.1348422,5.00900201 10.6308421,5.00900201 Z"></path>
                        <path d="M9.54388573,18.009002 C9.54388573,18.5590021 9.09388568,19.009002 8.54388573,19.009002 C7.99388572,19.009002 7.54388573,18.5590021 7.54388573,18.009002 L7.54388573,14.009002 C7.54388573,13.459002 7.99388572,13.009002 8.54388573,13.009002 C9.09388568,13.009002 9.54388573,13.459002 9.54388573,14.009002 L9.54388573,18.009002 Z"></path>
                        <path d="M6.54388573,17.009002 C5.99388575,17.009002 5.54388573,16.559002 5.54388573,16.009002 L5.54388573,16.009002 C5.54388573,15.459002 5.99388575,15.009002 6.54388573,15.009002 L10.5438857,15.009002 C11.0938858,15.009002 11.5438857,15.459002 11.5438857,16.009002 L11.5438857,16.009002 C11.5438857,16.559002 11.0938858,17.009002 10.5438857,17.009002 L6.54388573,17.009002 Z"></path>
                      </g>
                      <path
                        d="M16.304348,23.990998 L9.69565219,23.990998 C9.31130436,23.990998 9,23.766998 9,23.490998 C9,23.214998 9.31130436,22.990998 9.69565219,22.990998 L16.304348,22.990998 C16.6883478,22.990998 17,23.214998 17,23.490998 C17,23.766998 16.6883478,23.990998 16.304348,23.990998 Z"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M21.3333334,23.990998 L19.6666667,23.990998 C19.2983333,23.990998 19,23.766998 19,23.490998 C19,23.214998 19.2983333,22.990998 19.6666667,22.990998 L21.3333334,22.990998 C21.7013333,22.990998 22,23.214998 22,23.490998 C22,23.766998 21.7013333,23.990998 21.3333334,23.990998 Z"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M14.2799714,26.990998 L9.72002882,26.990998 C9.3222129,26.990998 9,26.766998 9,26.490998 C9,26.214998 9.3222129,25.990998 9.72002882,25.990998 L14.2799714,25.990998 C14.6774272,25.990998 15,26.214998 15,26.490998 C15,26.766998 14.6777869,26.990998 14.2799714,26.990998 Z"
                        fill="#FFFFFF"
                      ></path>
                    </g>
                  </g>
                </svg>

                <button className="btn">Health Information</button>
              </div>
            </Link>
          </div>
        </div>
        <div className="appointment-info">
          <div className="consultation-info">
            <div className="consultation-info-icon-container display-flex flex-center">
              <PhoneConsultationIcon />
            </div>
            <div className="consultation-info-description">
              <div className="consultation-text">
                To book an appointment with a GP 24/7 call:
              </div>
              <div className="consultation-phone">0345 319 2881</div>
              <Link to={ScreenRoutes.REQUEST_APPOINTMENT}>
                or use our online booking form >
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="footer display-flex flex-center">
          <img
            className="medsol-logo"
            src={medsolLogoImg}
            alt="HealthHero Logo"
          />
          <div className="footer-text">
            GP24 is a service provided by HealthHero Solutions Limited,
            specialists in 24/7 Private GP services.
          </div>
        </div>
      </div>
    </div>
  )
})
